import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAuth } from "../context/AuthContext";
import { User } from "../types/IcarusTypes";

export const useIntercom = () => {
  const location = useLocation();
  const [{ me }] = useAuth();

  useEffect(() => {
    // check whether to update intercom with this pageview. only update if
    // user does not exist or user exists and has blindLogging disabled
    if ((me && me.plan && !me.plan.permissions.blindLogging) || !me || !me.plan) {
      window.Intercom("update", { last_request_at: parseInt((new Date().getTime() / 1000).toString()) });
    }
  }, [location, me]);
};

export const callIntercom = (user?: User) => {
  // if user is logged in, check to make sure we're allowed to connect to intercom.
  // users with blind logging should only have an anonymous boot to intercom,
  // then no page refreshes sent to intercom
  if (user && user.plan && !user.plan.permissions.blindLogging) {
    window.Intercom("boot", {
      app_id: process.env.REACT_APP_INTERCOM,
      email: user.email,
      user_id: user.id,
      hide_default_launcher: true
    });
    return;
  } else if (!user || !user.plan) {
    // if user not logged in, boot intercom with the anonymous user.
    // don't boot if there is blind logging (that only happens when user opens messenger)
    window.Intercom("boot", {
      app_id: process.env.REACT_APP_INTERCOM,
      hide_default_launcher: true
    });
  }
};

export const showIntercomMessenger = () => {
  // we only boot for anonymous users when they open the messenger.
  // this will be a noop if the user was non-anonymous and they already booted
  window.Intercom("boot", {
    app_id: process.env.REACT_APP_INTERCOM,
    hide_default_launcher: true
  });
  window.Intercom("show");
};
