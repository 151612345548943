import React, { FC } from "react";
import { FallbackProps } from "react-error-boundary";

import NotFoundBackground from "../notFoundBackground/NotFoundBackground";
import styles from "./ErrorFallback.module.scss";

const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div className={styles.container}>
      <NotFoundBackground showAnimation={true}>
        <div className={styles.content}>
          <div>Oops, something went wrong!</div>
          <pre className={styles.pre}>{error.message}</pre>
          <a href="/">Go Home</a>
        </div>
      </NotFoundBackground>
    </div>
  );
};

export default ErrorFallback;
