import { Icon, Tag } from "@blueprintjs/core";
import { getAlerts } from "api/icarusApi";
import { alertsRefreshTime } from "config";
import { useAuth } from "context/AuthContext";
import React, { FC } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { routes } from "routes";

type Props = {
  isAuthenticated?: boolean;
  onClose?: () => void;
  linkClassName: string;
};

const AlertBadgeLinkMenuItem: FC<Props> = ({ onClose, isAuthenticated, linkClassName }) => {
  const [, , { handleError }] = useAuth();
  const { data } = useQuery("alerts", getAlerts, {
    enabled: isAuthenticated,
    refetchInterval: alertsRefreshTime,
    onError: (error: any) => {
      handleError({ error, showErrorToast: false });
    }
  });
  const badgeNumber = data?.totalAlertHits;

  return (
    <Link to={routes.alerts.path} className={linkClassName} onClick={onClose}>
      <Icon icon="notifications"></Icon>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span>Alerts</span>
        {badgeNumber !== undefined && badgeNumber > 0 && (
          <Tag intent="danger" round data-testid="numberAlertBadge">
            {badgeNumber > 99 ? "99+" : badgeNumber}
          </Tag>
        )}
      </div>
    </Link>
  );
};

export default AlertBadgeLinkMenuItem;
