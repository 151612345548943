import { CancelToken, CancelTokenSource } from "axios";
import axios from "axios";

import * as IcarusTypes from "../types/IcarusTypes";
import API from "./icarusApiConfig";

export const getAxiosCancelToken = () => {
  return axios.CancelToken.source();
};

export const login = async ({ email, password }: IcarusTypes.LoginInput) => {
  const bodyFormData = new FormData();
  bodyFormData.set("email", email);
  bodyFormData.set("password", password);
  const response = await API.post("/login", bodyFormData, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" }
    // withCredentials:true
  });

  return response.data as IcarusTypes.LoginResult;
};

export const logout = async () => {
  const response = await API.post("/logout");
  return response.data;
};

export const getFlightsAdvanced = async (
  data: IcarusTypes.NewAdvancedSearchRequest,
  currentPage?: number,
  resultsPerPage: number = 25,
  cancelTokenSource?: CancelTokenSource
): Promise<IcarusTypes.ListFlightsResult> => {
  const response = await API.post(`/query/flights`, data, {
    cancelToken: cancelTokenSource?.token,
    params: { children: "load", page: currentPage, pageSize: resultsPerPage }
  });
  return response.data;
};

export const getAllFlightsCSV = async (data: IcarusTypes.NewAdvancedSearchRequest): Promise<string> => {
  const apiEndpoint = `/query/flights?format=csv`;
  const response = await API.post(apiEndpoint, data);
  return response.data;
};

export const searchAircraft = async (
  data: IcarusTypes.NewAdvancedSearchRequest,
  currentPage?: number,
  resultsPerPage: number = 25,
  cancelTokenSource?: CancelTokenSource
): Promise<IcarusTypes.AircraftQueryResponse> => {
  //current page is 1 based. Offset is zero based. We have to convert
  // const offset = currentPage ? (currentPage - 1) * resultsPerPage : 1;
  const response = await API.post(`/query/aircraft?page=${currentPage}&pageSize=${resultsPerPage}`, data, {
    cancelToken: cancelTokenSource?.token
  });
  return response.data;
};

export const searchAircraftChildren = async (
  data: IcarusTypes.NewAdvancedSearchRequest,
  startTime: string,
  endTime: string,
  cancelToken?: CancelToken
): Promise<IcarusTypes.AircraftChildrenQueryResponse> => {
  const response = await API.post(`/query/aircraft?children=load&start_time=${startTime}&end_time=${endTime}`, data, {
    cancelToken: cancelToken
  });

  return response.data;
};

export const register = async (data: IcarusTypes.SignUpInput): Promise<IcarusTypes.RegisterUserResponse> => {
  const response = await API.post("/users", data);
  return response.data;
};

export const getUserData = async (id: number): Promise<IcarusTypes.User> => {
  const response = await API.get(`/users/${id}`);
  return response.data;
};

export const getUserBillingPortalURL = async (id: number): Promise<string> => {
  const response = await API.get(`/users/${id}/billingPortal`);
  return response.data.billingPortalURL;
};

export const updateUser = async (data: IcarusTypes.PersonalDataInput): Promise<{ message: string }> => {
  let requestData: any = {
    firstName: data.firstName,
    lastName: data.lastName
  };
  if (data.password) {
    requestData.password = data.password;
  }
  const response = await API.post("/users/" + data.id, requestData);
  return response.data;
};

export const getBasicSearch = async (text: string): Promise<IcarusTypes.BasicSearchResponse> => {
  const response = await API.get(`/search?q=${text}&d=aircraft,areas,registrants`);
  return response.data;
};

export const validateEmail = async (key: string) => {
  const response = await API.post(`/validate?key=${key}`);
  return response.data;
};

export const forgotPassword = async (emailAddress: string) => {
  const bodyFormData = new FormData();
  bodyFormData.set("email", emailAddress);
  const response = await API.post(`/forgotPassword`, bodyFormData, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" }
  });
  return response.data;
};

export const requestResetPassword = async ({
  key,
  password,
  agreedToTermsTime
}: {
  key: string;
  password: string;
  agreedToTermsTime?: string;
}) => {
  const bodyFormData = new FormData();
  bodyFormData.set("password", password);
  bodyFormData.set("key", key);
  if (agreedToTermsTime) {
    bodyFormData.set("agreedToTermsTime", agreedToTermsTime);
  }
  const response = await API.post(`/resetPassword`, bodyFormData, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" }
  });
  return response.data;
};

export const getAircraftData = async (icao: number): Promise<IcarusTypes.Aircraft> => {
  const response = await API.get(`aircraft/${icao}`);
  return response.data;
};

export const searchAircraftType = async (query: string): Promise<IcarusTypes.AircraftType[]> => {
  const response = await API.get(`/search`, { params: { q: query, d: IcarusTypes.SearchDomain.AircraftModels } });
  return response.data.models;
};

export const searchZones = async (query: string): Promise<IcarusTypes.SearchZonesResponse> => {
  const response = await API.get(`/search`, { params: { q: query, d: IcarusTypes.SearchDomain.areas } });
  return response.data;
};

export const searchAircraftTags = async (query: string): Promise<IcarusTypes.SearchAircraftCollectionResponse> => {
  const response = await API.get(`/search`, {
    params: { q: query, d: IcarusTypes.SearchDomain.AircraftCollections }
  });
  return response.data;
};

export const searchRegistrants = async (query: string): Promise<IcarusTypes.SearchRegistrantResponse> => {
  const response = await API.get(`/search`, {
    params: { q: query, d: IcarusTypes.SearchDomain.Registrants }
  });
  return response.data;
};

export const getAlerts = async (): Promise<IcarusTypes.GetAlertsApiResponse> => {
  const response = await API.get("/alerts");
  return response.data as IcarusTypes.GetAlertsApiResponse;
};

export const saveAlert = async (alert: IcarusTypes.SaveAlertRequest): Promise<IcarusTypes.Alert> => {
  const response = await API.post("/alerts", alert);
  return response.data;
};

export const updateAlert = async (alert: IcarusTypes.SaveAlertRequest) => {
  const response = await API.post(`/alerts/${alert.id}`, alert);
  return response.data;
};

export const deleteAlert = async (alert: IcarusTypes.Alert): Promise<string> => {
  const response = await API.delete(`/alerts/${alert.id}`);
  return response.data;
};

export const getPlans = async (): Promise<IcarusTypes.Plan[]> => {
  const response = await API.get("/plans");
  return response.data;
};

export const getFlightsByAlert = async (
  id: number,
  currentPage: number,
  pageSize: number,
  shouldClear: boolean
): Promise<IcarusTypes.ListFlightsResult> => {
  // const offset = currentPage ? (currentPage - 1) * 25 : 1;
  const response = await API.get(`/alerts/${id}`, {
    params: { shouldClear, shouldSearch: !shouldClear, page: currentPage, pageSize }
  });
  return response.data;
};

export const getRadarData = async (
  coordinates: IcarusTypes.MapCoordinates
): Promise<IcarusTypes.AircraftRadarData[]> => {
  const coordinatesArray = `[[${coordinates.topLeft}],[${coordinates.topRight}],[${coordinates.bottomRight}],[${coordinates.bottomLeft}]]`;
  const { data } = await API.get(`/radar?mapWindow=${coordinatesArray}`);
  return data;
};

export const getMyReceivers = async (): Promise<IcarusTypes.Receiver[]> => {
  const { data } = await API.get("/receivers");
  return data;
};

export const getReceiver = async (id: number): Promise<IcarusTypes.Receiver | undefined> => {
  const { data } = await API.get(`/receivers/${id}`);
  return data;
};

export const updateReceiver = async (receiver: IcarusTypes.Receiver): Promise<IcarusTypes.Receiver> => {
  const response = await API.post(`/receivers/${receiver.id}`, receiver);
  return response.data;
};

export const deleteReceiver = async (receiver: IcarusTypes.Receiver): Promise<void> => {
  const response = await API.delete(`/receivers/${receiver.id}`);
  return response.data;
};

export const createReceiver = async (data: IcarusTypes.CreateReceiverRequest): Promise<void> => {
  const response = await API.post("/receivers//", data);
  return response.data;
};

export const getMyItems = async (): Promise<IcarusTypes.MyItem[]> => {
  const response = await API.get("/items");
  return response.data;
};

export const getItemDirectoryItems = async (id: number): Promise<IcarusTypes.MyItem[]> => {
  const response = await API.get("/items", { params: { parent: id } });
  return response.data;
};

export const searchItems = async (query: string): Promise<IcarusTypes.SearchItemsResponse> => {
  const response = await API.get(`/search`, {
    params: {
      q: query,
      d: `${IcarusTypes.SearchDomain.AircraftCollections},${IcarusTypes.SearchDomain.directories},${IcarusTypes.SearchDomain.SavedAircraft}`
    }
  });
  return response.data;
};

export const saveAircraftToAircraftCollection = async (aircraftCollectionId: number, icao: number): Promise<void> => {
  const bodyFormData = new FormData();
  bodyFormData.set("icao", icao.toString());
  const response = await API.post(`/items/aircraft-collection/${aircraftCollectionId}/members`, bodyFormData);
  return response.data;
};

export const saveAreaToAreaCollection = async (targetAreaId: number, areaId: number): Promise<void> => {
  const bodyFormData = new FormData();
  bodyFormData.set("id", areaId.toString());
  const response = await API.post(`/items/area/${targetAreaId}/members`, bodyFormData);
  return response.data;
};

export const deleteAircraftFromAircraftCollection = async (data: { aircraftCollectionId: number; icao: number }) => {
  const bodyFormData = new FormData();
  bodyFormData.set("icao", data.icao.toString());
  const response = await API.delete(`/items/aircraft-collection/${data.aircraftCollectionId}/members`, {
    data: bodyFormData
  });
  return response.data;
};

export const getSingleItem = async (data: { type: string; id: number }): Promise<IcarusTypes.MyItem> => {
  const response = await API.get(`/items/${data.type}/${data.id}`);
  return response.data;
};

export const getAircraftCollection = async (data: { id: number }): Promise<IcarusTypes.AircraftCollectionItem> => {
  const response = await API.get(`/items/${IcarusTypes.ItemType.aircraftCollection}/${data.id}`);
  return response.data;
};

export const createMyItem = async (data: { myItem: IcarusTypes.SaveMyItemRequest; type: IcarusTypes.ItemType }) => {
  const response = await API.post(`/items/${data.type}`, data.myItem);
  return response.data;
};

export const updateDirectory = async (data: {
  myItem: IcarusTypes.SaveMyItemRequest;
  type: IcarusTypes.ItemType;
}): Promise<IcarusTypes.MyItem> => {
  const response = await API.post(`/items/${data.type}/${data.myItem.id}`, {
    name: data.myItem.name,
    shared: data.myItem.shared
  });
  return response.data;
};

export const updateMyItem = async (data: { myItem: IcarusTypes.SaveMyItemRequest; type: IcarusTypes.ItemType }) => {
  const response = await API.post(`/items/${data.type}/${data.myItem.id}`, data.myItem);
  return response.data;
};

export const deleteMyItem = async (data: { itemType: IcarusTypes.ItemType; id: number }) => {
  const response = await API.delete(`/items/${data.itemType}/${data.id}`);
  return response.data;
};

export const getAircraftPingsAsCSV = async (
  data: IcarusTypes.NewAdvancedSearchRequest,
  startTime: string,
  endTime: string
) => {
  const response = await API.post(
    `/query/pings?format=csv&children=load&start_time=${startTime}&end_time=${endTime}`,
    data
  );
  return response.data;
};

export const getSingleArea = async (data: { id: number }): Promise<IcarusTypes.Area> => {
  const response = await API.get(`/items/${IcarusTypes.ItemType.area}/${data.id}`);
  return response.data;
};

export const updateArea = async (data: { area: IcarusTypes.Area }) => {
  const response = await API.post(`/items/${IcarusTypes.ItemType.area}/${data.area.id}`, data.area);
  return response.data;
};

export const createArea = async (data: { area: IcarusTypes.Area }) => {
  const response = await API.post(`/items/${IcarusTypes.ItemType.area}`, data.area);
  return response.data;
};

export const sendInvite = async (data: { id: number; invite: IcarusTypes.InvitePerson }) => {
  const response = await API.post(`/users/${data.id}/invites`, data.invite);
  return response.data;
};
