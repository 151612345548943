import { Button, InputGroup } from "@blueprintjs/core";
import classNames from "classnames";
import React, { useState } from "react";

import styles from "./SearchInput.module.scss";

type Props = {
  className?: string;
  onSearch?: (text: string) => void;
};

const SearchInput: React.FC<Props> = ({ className, onSearch }) => {
  const [searchText, setSearchText] = useState<string>("");

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const val = (e.target as HTMLInputElement).value;
    setSearchText(val);
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (onSearch && searchText.trim().length > 0) {
      onSearch(searchText);
    }
  };

  return (
    <form onSubmit={handleSearch}>
      <InputGroup
        className={classNames(styles.input, className)}
        placeholder="Search..."
        leftIcon="search"
        rightElement={
          searchText ? (
            <Button icon="cross" minimal onClick={() => setSearchText("")}></Button>
          ) : (
            <div className={styles.dummyElement}></div>
          )
        }
        value={searchText}
        onChange={handleChange}
      ></InputGroup>
    </form>
  );
};

export default SearchInput;
