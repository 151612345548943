import "./index.scss";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ErrorFallback from "components/common/errorFallback/ErrorFallback";
import React from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { setRTLTextPlugin } from "react-map-gl";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";

// import { createInstance, MatomoProvider } from "@datapunt/matomo-tracker-react";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: `${process.env.REACT_APP_NAME}-${process.env.REACT_APP_VERSION}`,
  environment: process.env.NODE_ENV,
  beforeSend(event) {
    // could censor values here if necessary
    return event;
  }
});

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  Sentry.captureException(error);
};

/*
const instance = createInstance({
  urlBase: "https://c4ads.matomo.cloud/",
  siteId: 1,
  trackerUrl: "https://c4ads.matomo.cloud/matomo.php", // optional, default value: `${urlBase}matomo.php`
  srcUrl: "//cdn.matomo.cloud/c4ads.matomo.cloud/matomo.js" // optional, default value: `${urlBase}
});
*/

setRTLTextPlugin(
  // find out the latest version at https://www.npmjs.com/package/@mapbox/mapbox-gl-rtl-text
  "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
  undefined,
  // lazy: only load when the map first encounters Hebrew or Arabic text
  true
);

const Icarus = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      }
    }
  });

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
            <App />
          </ErrorBoundary>
          <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(<Icarus />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
