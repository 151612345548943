import { Classes, Drawer, Icon, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import AlertBadgeLinkMenuItem from "components/common/alertBadgeLinkMenuItem/AlertBadgeLinkMenuItem";
import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as DirectoryIcon } from "../../../assets/images/directory.svg";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { routes } from "../../../routes";
import { showIntercomMessenger } from "../../../utils/useIntercom";
import AdvancedSearchIcon from "../../icons/AdvancedSearchIcon";
import HelpIcon from "../../icons/HelpIcon";
import UserProfileIcon from "../../icons/UserProfileIcon";
import styles from "./MenuDrawer.module.scss";

type Props = {
  isOpen: boolean;
  isAuthenticated?: boolean;
  onClose?: () => void;
  onLogoutClicked?: () => void;
};

const MenuDrawer: React.FC<Props> = ({ isOpen, onClose, onLogoutClicked, isAuthenticated }) => {
  const hanleOnClickHelp = () => {
    showIntercomMessenger();
    if (onClose) {
      onClose();
    }
  };

  const renderAuthenticatedButtons = () => {
    return (
      <Menu>
        <Link to={routes.home.path} className={styles.menuItem} onClick={onClose}>
          <Icon icon="home"></Icon>
          <span>Home</span>
        </Link>
        <Link to={routes.radar.path} className={styles.menuItem} onClick={onClose}>
          <Icon icon="cell-tower"></Icon>
          <span>Radar View</span>
        </Link>
        <AlertBadgeLinkMenuItem
          isAuthenticated={isAuthenticated}
          linkClassName={styles.menuItem}
          onClose={onClose}
        ></AlertBadgeLinkMenuItem>
        <Link to={routes.advancedSearch.path} className={styles.menuItem} onClick={onClose}>
          <AdvancedSearchIcon />
          <span>Advanced Search</span>
        </Link>
        <Link to={routes.myItems.path} className={styles.menuItem} onClick={onClose}>
          <DirectoryIcon />
          <span>Items</span>
        </Link>
        <div className={styles.menuItem} onClick={hanleOnClickHelp}>
          <HelpIcon />
          <span>Help</span>
        </div>
        <Link to={routes.myProfile.path} className={styles.menuItem} onClick={onClose}>
          <UserProfileIcon />
          <span>Profile</span>
        </Link>
        <MenuItem aria-label="About" text="About" target="_blank" href="https://icarus.flights"></MenuItem>
        <MenuDivider></MenuDivider>
        <MenuItem text="Log out" onClick={onLogoutClicked}></MenuItem>
      </Menu>
    );
  };

  return (
    <Drawer
      className={Classes.DARK}
      isOpen={isOpen}
      title={<Logo className={styles.logo} />}
      onClose={onClose}
      position="left"
      size="320px"
    >
      <div className={Classes.DRAWER_BODY} data-testid="MenuDrawer">
        {isAuthenticated && renderAuthenticatedButtons()}
      </div>
    </Drawer>
  );
};

export default MenuDrawer;
