import React from "react";

type Props = {
  fill?: string;
};

const HelpIcon: React.FC<Props> = props => {
  const style1 = { fill: "none", stroke: "#068dc9", strokeWidth: "2px" };
  const style2 = { fill: "#ccc", stroke: "#ccc", strokeWidth: "0.3px" };
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <circle style={style1} cx="9" cy="9" r="8" />
          <path
            style={style2}
            d="M7.5,11.4V11a1.72,1.72,0,0,0,.33-.81,2.15,2.15,0,0,1,.32-.67,5.85,5.85,0,0,1,.69-.84l.37-.39c.07-.07.17-.2.3-.36l.39-.49a1.8,1.8,0,0,0,.49-1.06A1,1,0,0,0,10,5.61,1.71,1.71,0,0,0,8.9,5.29a4.27,4.27,0,0,0-1.05.14C7.5,5.52,6.5,6,6.5,6V4.91a10.93,10.93,0,0,1,2.38-.53,3.2,3.2,0,0,1,2,.54,1.68,1.68,0,0,1,.69,1.39,2.59,2.59,0,0,1-.89,1.75l-.39.4-.46.48-.41.42A3.33,3.33,0,0,0,9,10a3.16,3.16,0,0,1-.51.93v.51ZM8.35,14a.84.84,0,0,1,0-1.68.84.84,0,0,1,.84.84.82.82,0,0,1-.25.6A.84.84,0,0,1,8.35,14Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default HelpIcon;
