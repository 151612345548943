import { FC } from "react";
import shortid from "shortid";

import { ReactComponent as Aircraft } from "../../../assets/images/aircraft.svg";
import { ReactComponent as Tower } from "../../../assets/images/tower.svg";
import { randomIntFromInterval } from "../../../utils/utils";
import styles from "./NotFoundBackground.module.scss";

type Props = {
  showAnimation?: boolean;
};

const NotFoundBackground: FC<Props> = ({ children, showAnimation = true }) => {
  const renderAircraft = () => {
    const aircraft: JSX.Element[] = [];
    for (let i = 2; i < randomIntFromInterval(5, 8); i++) {
      aircraft.push(
        <Aircraft
          key={shortid.generate()}
          className={styles.aircraft}
          style={{ animationDuration: `${randomIntFromInterval(5, 25)}s`, top: `${randomIntFromInterval(0, 150)}px` }}
        ></Aircraft>
      );
    }
    return aircraft;
  };
  return (
    <div className={styles.container}>
      <div className={styles.sky}>{showAnimation && renderAircraft()}</div>
      <div className={styles.children}>{children}</div>
      <div className={styles.towerContainer}>
        <Tower className={styles.tower}></Tower>
      </div>
      <div className={styles.road}>
        <div className={styles.dash}></div>
      </div>
    </div>
  );
};

export default NotFoundBackground;
