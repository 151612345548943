import "./App.scss";

import FullPageSpinner from "components/common/fullPageSpinner/FullPageSpinner";
import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

// import { useMatomo } from "@datapunt/matomo-tracker-react";
import Header from "./components/common/header/Header";
import { useAuth } from "./context/AuthContext";
import { routes } from "./routes";
import { useIntercom } from "./utils/useIntercom";

const AuthenticatedApp = React.lazy(() => import("./AuthenticatedApp"));
const UnauthenticatedApp = React.lazy(() => import("./UnauthenticatedApp"));

function App() {
  useIntercom();

  const [state, , authHelpers] = useAuth();
  // const [{ me }] = useAuth();

  // const { pushInstruction } = useMatomo();

  const { logoutRequest } = authHelpers;
  const { isAuthenticated, isSettingUpApp } = state;

  const history = useHistory();

  const handleBasicSearch = (text: string) => {
    history.push(`${routes.search.path}?q=${encodeURI(text)}`);
  };

  const handleLogOut = () => {
    history.push(routes.home.path);
    logoutRequest();
  };

  /*
  useEffect(() => {
    setTimeout(() => {
      pushInstruction("setCustomVariable", "1", "user_id", me.id);
      pushInstruction("setCustomVariable", "1", "email", me.email);
      pushInstruction("setCustomVariable", "1", "enviroment", process.env.NODE_ENV);
      if (me.organization) {
        pushInstruction("setCustomVariable", "1", "org_id", me.organization.id);
      }
    }, 1000);
  }, [me, pushInstruction]);
*/
  if (isSettingUpApp) {
    return <FullPageSpinner></FullPageSpinner>;
  }

  return (
    <div className="app">
      <Helmet>
        <title>Icarus Flights</title>
        <meta
          name="description"
          content={`An uncensored flight analysis tool created for journalists and investigators.`}
        />
      </Helmet>
      <Header
        isAuthenticated={isAuthenticated}
        onLogoutClicked={handleLogOut}
        onBasicSearch={handleBasicSearch}
      ></Header>
      <Suspense fallback={<FullPageSpinner />}>
        {isAuthenticated ? <AuthenticatedApp></AuthenticatedApp> : <UnauthenticatedApp></UnauthenticatedApp>}
      </Suspense>
    </div>
  );
}

export default App;
