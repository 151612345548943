import axios from "axios";

import { apiEnpoint } from "../config";

function getErrorMessage(error: any) {
  const response = error.response;
  if (!response) return error;
  if (response.status === 418) return error;
  let errorMessage = "";
  const { data } = response;
  if (data) {
    errorMessage += data.message ? data.message : "";
    errorMessage += data.errorMessage ? data.errorMessage : "";
    if (data.error) {
      console.error(data.error);
    }
  }
  return errorMessage;
}

const adapter = axios.create({
  baseURL: apiEnpoint,
  timeout: 60 * 1000 * 10, // 10 mins
  headers: {
    Accept: "application/json"
  }
});

// Add a request interceptor
adapter.interceptors.request.use(
  request => {
    const token = localStorage.getItem("token");
    if (token && token.length > 0) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  error => {
    Promise.reject(error);
  }
);

adapter.interceptors.response.use(
  response => {
    if (response.status > 399) {
      return Promise.reject(response);
    }
    return response;
  },
  error => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && originalRequest.url === `${apiEnpoint}refresh`) {
      return Promise.reject(error);
    }
    //return error if its from login api call no need to retry
    if (error.response && error.response.status === 401 && originalRequest.url === `/login`) {
      const errorMessage = getErrorMessage(error);
      return Promise.reject(errorMessage);
    }

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const enpointUrl = apiEnpoint?.endsWith("/") ? apiEnpoint : `${apiEnpoint}/`;
      return axios
        .post(`${enpointUrl}refresh`, null, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
          // withCredentials: true
        })
        .then(res => {
          if (res.status === 200) {
            localStorage.setItem("token", res.data.token);
            axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
            originalRequest.headers.Authorization = `Bearer ${res.data.token}`;
            return axios(originalRequest);
          }
        })
        .catch(error => {
          const errorMessage = getErrorMessage(error);
          return Promise.reject(errorMessage);
        });
    }
    const errorMessage = getErrorMessage(error);
    return Promise.reject(errorMessage);
  }
);

export default adapter;
