import { createAction } from "redux-actions";

import { LoginInput, User } from "../types/IcarusTypes";

export const Types = {
  LOGIN_START: "LOGIN_START",
  LOGIN_FAILED: "LOGIN_FAILED",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  SET_SETUP_APP_STATE: "SET_SETUP_APP_STATE",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS"
};

export const loginStart = createAction(Types.LOGIN_START);
export const loginFailed = createAction<string>(Types.LOGIN_FAILED);
export const loginSuccess = createAction(Types.LOGIN_SUCCESS);
export const logoutSuccess = createAction(Types.LOGOUT_SUCCESS);
export const updateUserSuccess = createAction<User>(Types.UPDATE_USER_SUCCESS);
export const setIsSettingUpApp = createAction<boolean>(Types.SET_SETUP_APP_STATE);

export interface AuthHelpers {
  loginRequest: (val: LoginInput) => Promise<void>;
  logoutRequest: () => void;
  handleError: ({
    error,
    errorMessage,
    showErrorToast
  }: {
    error: unknown;
    showErrorToast?: boolean;
    errorMessage?: string;
  }) => void;
}
