import { Position3D } from "@deck.gl/core/utils/positions";

import { FlightState } from "../reducers/flight";

export interface RootState {
  flight: FlightState;
}

export type SearchFlightsQuery = {
  id?: number;
  icao?: number;
  reg?: string;
  call?: string;
  starttime?: Date;
  endtime?: Date;
  includePingsData?: boolean;
  includePathsData?: boolean;
  includeEventsData?: boolean;
};

export type MultiPolygonFeature = {
  type: "Feature";
  geometry: Multipolygon;
  properties: object | null;
};

export type PolygonFeature = {
  type: "Feature";
  geometry: Polygon;
  properties: object | null;
};

export type Multipolygon = {
  type: "MultiPolygon";
  coordinates: number[][][][] | null;
};

export type Polygon = {
  type: string;
  coordinates: number[][][] | null;
};

export type MultiPolygonFeatures = {
  type: "FeatureCollection";
  features: MultiPolygonFeature[];
};

export type PolygonFeatures = {
  type: "FeatureCollection";
  features: PolygonFeature[];
};

export type Geometry = {
  type: string;
  coordinates: Position3D;
};

export type LineGeometry = {
  type: string;
  coordinates: number[][];
};

export type GeoJsonPointFeature = {
  type: string;
  geometry: Geometry;
  properties: Ping;
};

export type LineFeature = {
  type: string;
  geometry: LineGeometry;
  properties: { icao: number; startTime: string } | null;
};

export type PingsGeoJson = {
  type: string;
  features: GeoJsonPointFeature[];
};

export type PathsGeoJson = {
  type: string;
  features: LineFeature[];
};

export type Flight = {
  icao: number;
  callSign?: string | null;
  startTime: string;
  endTime: string;
  originAreaName?: string | null;
  originCountry?: string | null;
  destAreaName?: string;
  destCountry?: string;
  reg?: string | null;
  model: AircraftModel;
  pingsGeoJSON?: PingsGeoJson;
  pathGeoJSON?: PathsGeoJson;
  events?: FlightEvent[];
  color?: [number, number, number];
  isQueryingEvents?: boolean;
  isQueryingGeoJson?: boolean;
};

export type ListFlightsResult = {
  results: Flight[] | null;
  duration: number;
  ["result_count"]: number;
};

export type AircraftQueryResponse = {
  results: Aircraft[] | null;
  duration: number;
  result_count: number;
};

export type AircraftWithEventsPings = Aircraft & {
  events: FlightEvent[];
  pingsGeoJSON: PingsGeoJson;
  pathGeoJSON: PathsGeoJson;
};

export type AircraftChildrenQueryResponse = {
  results: AircraftWithEventsPings[] | null;
  duration: number;
  ["result_count"]: number;
};

export type GetAreasResult = {
  areas: Area[];
};

export type LoginInput = {
  email: string;
  password: string;
};

export type LoginResult = {
  token: string;
};

export type SelectFlightCard = {
  flight: Flight;
  selected: boolean;
};

export type Ping = {
  icao: number;
  seen?: string;
  alt?: number;
  gs?: number;
  flight?: string;
  squawk?: number | null;
  mlat?: boolean | null;
  vsi?: number | null;
  trak?: number | null;
  coordinates: number[];
  messageType?: string;
  dataSource?: string;
};

export enum EventType {
  takeoff = "Takeoff",
  landing = "Landing",
  signalLost = "Signal Lost",
  signalFound = "Signal Restored"
}

export type FlightEvent = {
  eventType: keyof typeof EventType;
  areaName?: string;
  areaCountry?: string;
  startTime: string;
  ping: Ping;
};

export type FilterProps = {
  editable: boolean;
  errorMessage?: string;
  onChangeEditingState: (state: boolean) => void;
};

export type KindSearch = "all-of" | "any-of";

export interface IFilter {
  name: string;
  type: string;
  internalName: string;
  additionalInfo: { suggestType?: SuggestTypes };
  category: string;
  categoryId: number;
  showKindSearch?: boolean | undefined;
}

export type FilterResult = {
  name: string;
  values: any;
  kindSearch?: "all-of" | "any-of";
};

export enum QueryRequestFilters {
  "icao" = "icao",
  "flightTime" = "flight-time",
  "callSign" = "call-sign",
  "flightDestination" = "flight-destination",
  "flightOrigin" = "flight-origin",
  "modelId" = "model-id",
  "registration" = "registration",
  "registrationCountry" = "registration-country",
  "flightPath" = "flight-path",
  "aircraftTag" = "tag",
  "registrant" = "registrant"
}

export type ZoneRequestFilterTerm = {
  filter: QueryRequestFilters.flightDestination | QueryRequestFilters.flightOrigin | QueryRequestFilters.flightPath;
  zone_type: ZoneType;
  areaId: number | string;
  zone_name: string;
};

export type ZoneRequestFilter = {
  filter: "any-of";
  terms: ZoneRequestFilterTerm[];
};

export type TimeRangeRequestFilter = {
  filter: QueryRequestFilters.flightTime;
  from: string;
  to: string;
};

export type ValueRequestFilterTerm = {
  filter:
    | QueryRequestFilters.callSign
    | QueryRequestFilters.icao
    | QueryRequestFilters.modelId
    | QueryRequestFilters.registrationCountry
    | QueryRequestFilters.registration
    | QueryRequestFilters.registrant;
  value: string;
  name?: string;
};

export type ValueRequestFilter = {
  filter: "any-of";
  terms: ValueRequestFilterTerm[];
};

export type IdRequestFilterTerm = {
  filter: QueryRequestFilters.aircraftTag;
  id: string | number;
  name?: string;
};

export type IdRequestFilter = {
  filter: "any-of";
  terms: IdRequestFilterTerm[];
};

export type NewAdvancedSearchRequest = {
  filter: "all-of";
  terms: (TimeRangeRequestFilter | ValueRequestFilter | ZoneRequestFilter | IdRequestFilter)[];
};

export type TooltipData = {
  picked?: boolean;
  x: number;
  y: number;
  tooltipType?: "point" | "icon" | "line" | "plane" | undefined;
  data?: any;
};

export type GeoPointHover = {
  eventType?: keyof typeof EventType;
  properties?: Ping;
} & TooltipData;

export type PingWithPlaneReg = Ping & {
  reg?: string;
  modelTypeCode?: string;
  shortModelName?: string;
};
export type AircraftRadarDataHover = {
  eventType?: keyof typeof EventType;
  properties?: PingWithPlaneReg;
} & TooltipData;

export type LineHover = {
  flightId?: number;
} & TooltipData;

export type Area = MyItemRoot & {
  parentAreaId?: number | null;
  areaCount?: number;
  geography?: MultiPolygonFeatures | PolygonFeatures;
  children?: Area[];
  selectedFeatureIndexes?: number[];
};

export type FlightIdInput = {
  flightId: string;
};

export enum SearchFor {
  "flights" = "flights",
  "aircraft" = "aircraft"
}

export type Price = {
  stripePriceId: string;
  name: string;
  amount: string;
};

export type Plan = {
  stripeProductId: string;
  name: string;
  prices: Price[];
  features: any[] | null;
};

export enum Status {
  "failed" = "failed",
  "loading" = "loading",
  "resolved" = "resolved",
  "iddle" = "iddle"
}

export type SignUpInput = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm: string;
  affiliation: string;
  priceID: string;
  inviteCode: string;
};

export type User = {
  id: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  stripeCustomerId?: string;
  inviteQuota?: number;
  userType: "regular" | "org_admin" | "super_user";
  verified?: boolean;
  organization?: {
    id: number;
    name: string;
  };
  plan?: UserPlan;
};

export type PersonalDataInput = {
  firstName: string;
  lastName: string;
  password?: string;
  id: number;
};

export type SavedSearch = {
  id?: number;
  name: string;
  time: string;
  filters: FilterResult[];
  archived?: boolean;
};

export type Alert = {
  id: number;
  name: string;
  time: string;
  frequency: string;
  email: string;
  phone: number;
  queryJSON: string;
  hitCount: number | null;
  creatorEmail?: string;
  creatorID?: number;
  lastSent?: string;
  filters?: FilterResult[];
};

export type GetAlertsApiResponse = {
  alerts: Alert[] | null;
  totalAlertHits: number;
};

export type GetAlertsResponse = {
  alerts: Alert[] | null;
  totalAlertHits: number;
};

export type SavedSearchResponse = {
  id: number;
  time: string;
  creatorEmail?: string;
  type?: 1;
  queryJSON: string;
  name: string;
};

export type GetSavedSearchesResult = {
  areas: SavedSearchResponse[] | null;
};

export type AircraftPhoto = {
  thumbUrl: string;
  linkTo?: string;
  author?: string;
  sourceName?: string;
};

export type RegistrationSource = {
  url: string;
  name: string;
  isOfficial: boolean;
};

export type AircraftRegistration = {
  registryCode: string;
  source: RegistrationSource;
  registrant: Registrant | null;
  registration?: string;
  country?: string;
  serialNumber?: string;
  manufacturer?: string;
  model?: string;
  year?: number;
  retrieved: string;
  updated: string | null;
  start: string | null;
  end: string | null;
  active: boolean | null;
};

export type AircraftTag = {
  id: number;
  icao: number;
  name: string;
  system: boolean;
};

export type AircraftModel = {
  id: number;
  modelTypeCode?: string;
  shortModelName?: string;
  longModelName?: string;
  manufacturer?: string;
  wtc?: string;
};

export type Aircraft = {
  icao: number;
  country?: string;
  reg?: string;
  model: AircraftModel;
  pings?: Ping[];
  aircraftCollections?: AircraftTag[];
  photos?: AircraftPhoto[] | null;
  registrations?: AircraftRegistration[];
  detailFields?: { field: string; value: string }[];
};

export type Airport = {
  id: number;
  code: string;
  name: string;
  type?: string;
  elevation?: number;
};

export type Locality = {
  id: number;
  name: string;
  country?: string;
};

export enum ArrivalDeparture {
  "arrival" = "arrival",
  "departure" = "departure"
}

export type BasicSearchResponse = {
  aircraft?: Aircraft[] | null;
  areas?: Area[] | null;
  registrants?: Registrant[] | null;
};

export type AircraftType = {
  id: number;
  shortModelName: string;
  longModelName?: string;
  modelTypeCode?: string;
  wtc?: string;
};

export enum ZoneType {
  "airport" = "airport",
  "jurisdiction" = "jurisdiction",
  "areaOfInterest" = "area-of-interest"
}

export enum SuggestTypes {
  "model" = "model",
  "location" = "location",
  "country" = "country",
  "intersectsZone" = "intersectsZone",
  "registrant" = "registrant",
  "aircraftTag" = "aircraftTag"
}

export enum FilterComponentType {
  "date" = "date",
  "tag" = "tag",
  "suggest" = "suggest"
}

export enum CriteriaFilterType {
  "flights" = "flights",
  "aircraft" = "aircraft",
  "alerts" = "alerts",
  "trackingHistory" = "trackingHistory"
}

export type SugestionRequest = {
  type: SuggestTypes;
  text: string;
};

export interface ISuggestItem {
  id: any;
  name: string;
  type?: ZoneType | string;
}

export type SearchZonesResponse = {
  airports?: Airport[];
  localities?: Locality[];
  areas?: Area[];
};

export type FilterType = {
  id: string;
  filter: IFilter;
  values: any;
  isEditing: boolean;
  errorMessage?: string;
  kindSearch?: "all-of" | "any-of";
};

export type RangeFilterValues = { from: number; to: number };

export type AdvancedSearchInitialFilters = {
  filters: FilterResult[];
  isEditing?: boolean;
};

export interface IFrequency {
  id: any;
  name: string;
}

export interface IResultPerPage {
  id: number;
  name: string;
}

export type IKindSearchFilter = {
  id: "all-of" | "any-of";
  name: string;
};

export interface IItemSortBy {
  id: keyof Pick<MyItem, "created" | "ownerEmail" | "name" | "type">;
  name: string;
}

export type Shape = {
  w: number;
  h: number;
  viewBox?: string;
  strokeScale?: number;
  path?: string | string[];
  transform?: string;
  svg?: string;
  noAspect?: boolean;
  accentMult?: number;
  accent?: string | string[];
  noRotate?: boolean;
  size?: [number, number];
};

export type SearchByFiltersRequest = {
  filters: FilterResult[];
  currentPage: number;
  filtersEncoded?: string;
  perPage: number;
};

export type SearchFlightsByAlertRequest = {
  id: number;
  currentPage?: number;
};

export type CreatePaymentIntentResponse = {
  paymentIntentID: string;
  amount: number;
  clientSecret: string;
};

export type SaveAlertRequest = {
  id?: number;
  name: string;
  filters: NewAdvancedSearchRequest;
  time: string;
  frequency: string;
  email: string;
  phone: number;
};

export type SearchRegistrantResponse = {
  registrants: ISuggestItem[];
};

export type Registrant = {
  id: number;
  name?: string;
  address?: string;
  phoneNumber?: string;
  emailAddress?: string;
  isCompany?: boolean;
};

export type Job = {
  id: number;
  name: string;
  creationDate: string;
};

export type MapCoordinates = {
  topLeft: number[];
  topRight: number[];
  bottomLeft: number[];
  bottomRight: number[];
  center?: number[];
};

export enum MapMode {
  "radar" = "radar",
  "flights" = "flights",
  "modifyAreaDrawing" = "modifyAreaDrawing",
  "moveAreaDrawing" = "moveAreaDrawing",
  "drawPolygon" = "drawPolygon",
  "trackingPings" = "trackingPings"
}

export type AircraftRadarData = Aircraft & {
  latestPing: Ping;
};

export type Receiver = {
  id: number;
  owner: number;
  uuidEncrypted: string;
  name: string;
  created: string;
  online?: boolean;
  lastOnline: string;
  range: PolygonFeatures;
  rangeAge: string;
};

export type MyEditPageQueryString = {
  id: number | "new";
  type: ItemType;
  parentId?: number;
};

export type DateValueId = {
  date: string;
  value: number;
  id: number;
};

export type PlanPermissions = {
  alertsEmail: number;
  alertsSms: number;
  areas: boolean;
  daysHistory: number;
  expEmb: number;
  expCsv: number;
  nonComm: boolean;
  orgSharing: boolean;
  regData: boolean;
  searches: boolean;
  live: true;
  spdAltSearch: boolean;
  blindLogging?: boolean;
};

export type UserPlan = {
  display_name: string;
  permissions: PlanPermissions;
  seats: number;
};

export type JwtValue = {
  exp: number;
  iat: number;
  nbf: number;
  user: User;
};

export type RegisterUserResponse = {
  checkoutSessionID?: string;
  message?: string;
};

export enum ItemType {
  "directory" = "directory",
  "area" = "area",
  "aircraftCollection" = "aircraft-collection",
  "savedAircraft" = "saved-aircraft",
  "aircraft" = "aircraft",
  "container" = "container"
}

export type MyItemType = {
  id: ItemType;
  name: string;
};

export type SaveMyItem = {
  id?: number | null;
  parentId?: number | null;
  name?: string;
  shared?: boolean;
  system?: boolean;
};

export type SaveMyAircraftItem = {
  id?: number | null;
  parentId?: number;
  name?: string;
  shared?: boolean;
  system?: boolean;
  icao?: number;
};
export type SaveMySearchItem = {
  id?: number | null;
  parentId?: number;
  name?: string;
  shared?: boolean;
  system?: boolean;
  filters?: FilterResult[];
};

export type SaveMyAreaItem = {
  id?: number | null;
  parentId?: number;
  name?: string;
  shared?: boolean;
  system?: boolean;
  geography?: any;
};

export type SearchAircraftCollectionResponse = {
  "aircraft-collections": MyItem[];
};

export type SaveMyItemRequest = SaveMyItem | SaveMyAircraftItem | SaveMySearchItem | SaveMyAreaItem;

export type MyItemRoot = {
  id: number | null;
  created?: string;
  name: string;
  shared?: boolean;
  parentShared?: boolean;
  system?: boolean;
  parentId?: number | null;
  ownerId?: number | null;
  ownerEmail?: string;
};

export type MyItem = MyItemRoot & {
  id: number | null;
  type?: ItemType;
  parentAreaId?: number | null;
  icao?: number;
  filters?: FilterResult[];
  members?: MyItem[] | null;
};

export type AircraftCollectionItem = MyItemRoot & {
  members: Aircraft[];
};

export type GetMyItemResponse = {
  items: MyItem[] | null;
};

export enum TrackingChartType {
  "altitude" = "altitude",
  "speed" = "speed"
}

export enum SearchDomain {
  "Aircraft" = "aircraft",
  "AircraftModels" = "models",
  "Registrants" = "registrants",
  "SavedFlights" = "saved-flights",
  "SavedAircraft" = "saved-aircraft",
  "areas" = "areas",
  "directories" = "directories",
  "AircraftCollections" = "aircraft-collections"
}

export type SearchItemsResponse = {
  "aircraft-collections": MyItem[];
  directories: MyItem[];
  "saved-aircraft": MyItem[];
  "saved-flights": MyItem[];
};

export type CreateReceiverRequest = {
  uuidEncrypted: string;
  name: string;
};

export type FlatChartData = Ping & {
  reg?: string | null;
  icao?: number;
  eventType?: string;
};

export type InvitePerson = {
  email: string;
  firstName: string;
  lastName: string;
};
