import { Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import React from "react";
import { useHistory } from "react-router-dom";

import { routes } from "../../../routes";

export type Props = {
  onLogoutClicked?: () => void;
};

const MenuAccount: React.FC<Props> = props => {
  const { onLogoutClicked } = props;
  const history = useHistory();

  return (
    <Menu>
      <MenuItem aria-label="Profile" text="Profile" onClick={() => history.push(routes.myProfile.path)}></MenuItem>
      <MenuItem
        aria-label="My Receivers"
        text="My Receivers"
        onClick={() => history.push(routes.myReceivers.path)}
      ></MenuItem>
      <MenuDivider></MenuDivider>
      <MenuItem
        aria-label="Terms"
        text="Legal Summary"
        onClick={() => history.push(routes.termsOfService.path)}
      ></MenuItem>
      <MenuItem aria-label="About" text="About" target="_blank" href="https://icarus.flights"></MenuItem>
      <MenuDivider></MenuDivider>
      <MenuItem aria-label="Log out" text="Log out" icon="log-out" onClick={onLogoutClicked}></MenuItem>
    </Menu>
  );
};

export default MenuAccount;
