import { Button, Icon } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as DirectoryIcon } from "../../../assets/images/directory.svg";
import AdvancedSearchIcon from "../../icons/AdvancedSearchIcon";
import HelpIcon from "../../icons/HelpIcon";
import UserProfileIcon from "../../icons/UserProfileIcon";
import styles from "./NavbarButton.module.scss";

type Props = {
  type: "account" | "help" | "directory" | "advanced" | "home" | "alert" | "radar";
  tootltipText: string;
  className?: string;
  onClick?: () => void;
  to?: string;
  active?: boolean;
};

const NavbarButton: React.FC<Props> = ({ active, type, tootltipText, className, onClick, to }) => {
  const renderIcons = () => {
    switch (type) {
      case "account":
        return <UserProfileIcon></UserProfileIcon>;
      case "help":
        return <HelpIcon></HelpIcon>;
      case "directory":
        return <DirectoryIcon viewBox="0 -10 100 100" height="22px"></DirectoryIcon>;
      case "advanced":
        return <AdvancedSearchIcon></AdvancedSearchIcon>;
      case "home":
        return <Icon icon="home"></Icon>;
      case "alert":
        return <Icon icon="notifications"></Icon>;
      case "radar":
        return <Icon icon="cell-tower"></Icon>;
    }
  };
  if (!to) {
    return (
      <Tooltip2 content={tootltipText} placement="left" portalClassName={styles.positionTooltip}>
        <Button
          id={`navbarButton-${type}`}
          data-testid={`navbarButton-${type}`}
          minimal={true}
          className={classNames(styles.button, className)}
          onClick={onClick}
          active={active}
        >
          {renderIcons()}
        </Button>
      </Tooltip2>
    );
  }

  return (
    <Tooltip2 content={tootltipText} placement="left" portalClassName={styles.positionTooltip}>
      <Link to={to}>
        <Button
          id={`navbarButton-${type}`}
          data-testid={`navbarButton-${type}`}
          minimal={true}
          className={classNames(styles.button, className)}
          onClick={onClick}
          active={active}
        >
          {renderIcons()}
        </Button>
      </Link>
    </Tooltip2>
  );
};

export default NavbarButton;
