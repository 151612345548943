import { useEffect, useState } from "react";

export default function useMediaQuery(queryInput: string, options = {}) {
  const query = queryInput.replace(/^@media( ?)/m, "");
  const supportMatchMedia = typeof window !== "undefined" && typeof window.matchMedia !== "undefined";
  const matchMedia = supportMatchMedia ? window.matchMedia : null;
  const [match, setMatch] = useState(() => {
    if (supportMatchMedia && matchMedia) {
      return matchMedia(query).matches;
    }
    return false;
  });

  useEffect(() => {
    let active = true;

    if (!supportMatchMedia || !matchMedia) {
      return undefined;
    }

    const queryList = matchMedia(query);
    const updateMatch = () => {
      // Workaround Safari wrong implementation of matchMedia
      // https://github.com/mui-org/material-ui/pull/17315#issuecomment-528286677
      if (active) {
        setMatch(queryList.matches);
      }
    };
    updateMatch();
    queryList.addListener(updateMatch);
    return () => {
      active = false;
      queryList.removeListener(updateMatch);
    };
  }, [query, matchMedia, supportMatchMedia]);

  return match;
}
