import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { ReactComponent as EngineImage } from "../../../assets/images/engine.svg";
import { LoadingMessages } from "../../../utils/messages";
import { randomIntFromInterval } from "../../../utils/utils";
import styles from "./Loading.module.scss";

interface Props {
  size?: "large" | "standard" | "small";
}

const Loading: React.FC<Props> = ({ size = "standard" }) => {
  const [num, setNum] = useState(randomIntFromInterval(0, LoadingMessages.length - 1));
  const widths = { large: 100, standard: 50, small: 30 };

  useEffect(() => {
    const id = setInterval(() => {
      const selectedIndex = randomIntFromInterval(0, LoadingMessages.length - 1);
      setNum(num => selectedIndex);
    }, 2000);
    return () => clearInterval(id);
  }, []);
  return (
    <div className={styles.container} data-testid="Loading">
      <EngineImage style={{ width: widths[size] }} className={classNames(styles.engine)}></EngineImage>
      <span className={styles.message}>{LoadingMessages[num]}</span>
    </div>
  );
};

export default Loading;
