export const routes = {
  login: {
    path: "/login",
    title: "Log in"
  },
  home: {
    path: "/",
    title: "Home"
  },
  advancedSearch: {
    path: "/advanced-search",
    title: "Search Flights & Aircraft"
  },
  flights: {
    path: "/flights",
    title: "Flight Search"
  },
  areas: {
    path: "/areas",
    title: "Area"
  },
  search: {
    path: "/search",
    title: "Search"
  },
  alerts: {
    path: "/alerts",
    title: "Alerts"
  },
  aircraft: {
    path: "/aircraft",
    title: "Aircraft Search"
  },
  myReceivers: {
    path: "/my-receivers",
    title: "My Receivers"
  },
  myItems: {
    path: "/my-items",
    title: "My Items"
  },
  aircraftCollection: {
    path: "/aircraft-colletion",
    title: "Aircraft Collection"
  },
  myItemsEditDirectory: {
    path: "/my-items-edit",
    title: "My Items"
  },
  radar: {
    path: "/radar",
    title: "Radar"
  },
  trackingHistory: {
    path: "/tracking-history",
    title: "Track History"
  },
  profile: {
    path: "/profile",
    title: "Profile"
  },
  myProfile: {
    path: "/profile/me",
    title: "Profile"
  },
  receiversProfile: {
    path: "/profile/receivers",
    title: "Receivers"
  },
  alertFlights: {
    path: "/alert-flights",
    title: "Alert Flights"
  },
  signUp: {
    path: "/sign-up"
  },
  termsOfService: {
    path: "/terms-of-service",
    title: "Legal Summary"
  },
  plans: {
    path: "/plans"
  },
  validate: {
    path: "/validate"
  },
  passwordResetRequest: {
    path: "/password-reset-request"
  },
  setPassword: {
    path: "/set-password"
  },
  setNewPassword: {
    path: "/set-new-password"
  },
  checkoutFailure: {
    path: "/checkout-failure"
  },
  signUpSuccess: {
    path: "/signup-success"
  },
  invites: {
    path: "/profile/invites"
  }
};
