import produce, { Draft } from "immer";
import { Action } from "redux-actions";

import { Types } from "../actions/auth";
import { User } from "../types/IcarusTypes";

export interface AuthState {
  readonly isAuthenticated: boolean;
  readonly isLoading: boolean;
  readonly errorMessage: string;
  readonly me: User;
  readonly isSettingUpApp: boolean;
}

export const INITIAL_STATE: AuthState = {
  isAuthenticated: false,
  isLoading: false,
  errorMessage: "",
  isSettingUpApp: true,
  me: {
    firstName: "",
    lastName: "",
    email: "",
    id: -1,
    userType: "regular"
  }
};

const signUp = produce((draft: Draft<AuthState>, action: Action<any>) => {
  switch (action.type) {
    case Types.LOGIN_START:
      draft.isLoading = true;
      draft.errorMessage = "";
      return;
    case Types.LOGIN_SUCCESS:
      draft.isLoading = false;
      draft.errorMessage = "";
      draft.isAuthenticated = true;
      return;
    case Types.LOGIN_FAILED:
      draft.isLoading = false;
      draft.errorMessage = action.payload as string;
      draft.isAuthenticated = false;
      return;
    case Types.LOGOUT_SUCCESS:
      draft.isAuthenticated = false;
      draft.errorMessage = "";
      draft.me = { ...INITIAL_STATE.me };
      return;
    case Types.UPDATE_USER_SUCCESS:
      draft.me = { ...draft.me, ...(action.payload as User) };
      draft.isLoading = false;
      return;
    case Types.SET_SETUP_APP_STATE:
      draft.isSettingUpApp = action.payload as boolean;
      return;
  }
}, INITIAL_STATE);

export default signUp;
