import { Colors } from "@blueprintjs/core";
import { addMinutes, sub, subHours, subMonths } from "date-fns";
import { format, isValid, startOfToday } from "date-fns";

import { MultiPolygonFeatures, Plan, PolygonFeatures, Price, Shape } from "../types/IcarusTypes";

export function randomIntFromInterval(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const substractDaysFromDate = (date: Date, days: number) => {
  if (days < 1) throw new Error("Days greater than 0");
  return sub(date, { days });
};

export const getPastMonthRange = () => {
  const startTime = subMonths(startOfToday(), 1);
  const endTime = new Date();
  return { startTime, endTime } as { startTime: Date; endTime: Date };
};

export const getLastHoursRange = (hours: number) => {
  const startTime = subHours(startOfToday(), hours);
  const endTime = new Date();
  return { startTime, endTime } as { startTime: Date; endTime: Date };
};

export const hexToRGB = (h: string) => {
  let hex = h;
  hex = hex.replace(/^#/, "");
  const num = parseInt(hex, 16);
  const red = num >> 16;
  const green = (num >> 8) & 255;
  const blue = num & 255;

  return [red, green, blue] as [number, number, number];
};

export const randomColorsFromBluePrintJs = () => {
  //This colors are taken from Blueprint official colors: https://blueprintjs.com/docs/#core/colors
  const colorsExpected = ["BLUE", "COBALT", "GRAY", "LIGHT_GRAY"];
  const colorName = colorsExpected[randomIntFromInterval(0, colorsExpected.length - 1)];
  //Just take the shades of colors 1 2 or 3 which are not that light
  const colorShadeHexName = `${colorName}${randomIntFromInterval(1, 5)}`;
  //@ts-ignore
  const colorShadeHex = Colors[colorShadeHexName];
  return hexToRGB(colorShadeHex);
};

export const convertIntIcaoToHexIcaoString = (icao?: number) => {
  if (Number.isNaN(icao) || !icao) {
    return "";
  }
  if (!Number.isInteger(icao)) {
    return "";
  }
  if (icao < 0) {
    return "";
  }

  return icao.toString(16).padStart(6, "0").toUpperCase();
};

export const convertFromHexIcaoToIntIcao = (icao: string) => {
  return parseInt(icao, 16);
};

export const validateEmail = (valueToValidate: string) => {
  const regex =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return valueToValidate.match(regex) !== null;
};

export function svgShapeToURI(shape: Shape, fillColor: string, strokeColor: string, strokeWidth: number) {
  strokeWidth *= shape.strokeScale ? shape.strokeScale : 1;

  if (!shape.path) {
    let svg = (shape.svg as string)
      .replace("fillColor", fillColor)
      .replace("strokeColor", strokeColor)
      .replace("strokeWidth", strokeWidth.toString());
    return "data:image/svg+xml;base64," + btoa(svg);
  }

  let svg =
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="' +
    shape.viewBox +
    '" ' +
    (shape.noAspect ? 'preserveAspectRatio="none" ' : "") +
    'width="' +
    shape.w +
    '" height="' +
    shape.h +
    '">' +
    "<g" +
    (shape.transform ? ' transform="' + shape.transform + '"' : "") +
    ">";

  let path = shape.path;
  if (!Array.isArray(path)) path = [path];
  for (let i = 0; i < path.length; i++) {
    svg +=
      '<path paint-order="stroke" fill="' +
      fillColor +
      '" stroke="' +
      strokeColor +
      '" stroke-width="' +
      2 * strokeWidth +
      '" ' +
      'd="' +
      path[i] +
      '"/>';
  }

  let accentWidth = 0.6 * (shape.accentMult ? shape.accentMult * strokeWidth : strokeWidth);
  let accent = shape.accent;
  if (!Array.isArray(accent)) {
    accent = [accent || ""];
  }
  for (let i = 0; i < accent.length; i++) {
    svg += '<path fill="none" stroke="' + strokeColor + '" stroke-width="' + accentWidth + '" d="' + accent[i] + '"/>';
  }

  svg += "</g></svg>";

  return "data:image/svg+xml;base64," + btoa(svg);
}

export const formatDateToUTC = (
  dateString: string | null | undefined | Date,
  formatString: string = "MMM dd yyyy HH:mm"
) => {
  if (!dateString) {
    return "";
  }
  if (!isValid(new Date(dateString))) {
    return "";
  }

  const dateParsed = new Date(dateString);
  //this will manintain the UTC date that the server returns:
  return format(addMinutes(dateParsed, dateParsed.getTimezoneOffset()), formatString);
};

export const sortPlansByPrice = (planA: Plan, planB: Plan) => {
  let priceA = 0;
  let priceB = 0;
  planA.prices?.forEach(a => {
    let amt = parseInt(a.amount.replace(/\D/g, ""));
    if (amt > priceA) priceA = amt;
  });
  planB.prices?.forEach(b => {
    let amt = parseInt(b.amount.replace(/\D/g, ""));
    if (amt > priceB) priceB = amt;
  });
  return priceA - priceB;
};

export const sortPrices = (priceA: Price, priceB: Price): number => {
  let amountA = parseInt(priceA.amount.replace(/\D/g, ""));
  let amountB = parseInt(priceB.amount.replace(/\D/g, ""));
  return amountA - amountB;
};

export const existValueInEnum = (type: any, value: any): boolean => {
  return (
    Object.keys(type)
      .filter(k => isNaN(Number(k)))
      .filter(k => type[k] === value).length > 0
  );
};

export const getLongitudeLatitude = (
  geography?: MultiPolygonFeatures | PolygonFeatures
): { longitude: number; latitude: number } | null => {
  try {
    if (geography?.features[0].geometry.type === "MultiPolygon") {
      const latitude = (geography?.features[0].geometry.coordinates as number[][][][])[0][0][0][1] as number;
      const longitude = (geography?.features[0].geometry.coordinates as number[][][][])[0][0][0][0] as number;
      return { longitude, latitude };
    } else {
      const latitude = (geography?.features[0].geometry.coordinates as number[][][])[0][0][1] as number;
      const longitude = (geography?.features[0].geometry.coordinates as number[][][])[0][0][0] as number;
      return { longitude, latitude };
    }
  } catch (error) {
    return null;
  }
};

export const hasPolygons = (geography?: MultiPolygonFeatures | PolygonFeatures): boolean => {
  if (!geography) {
    return false;
  }
  return (
    geography.features.length > 0 &&
    geography.features[0].geometry.coordinates !== null &&
    geography.features[0].geometry.coordinates.length > 0
  );
};

export const isValidNumber = (val: string | undefined | null) => !Number.isNaN(Number.parseInt(val || ""));

export const getNumber = (val: string | undefined | null): number | null => {
  if (!isValidNumber(val)) {
    return null;
  } else {
    return Number.parseInt(val as string);
  }
};

export const addEllipsisToText = (text: string | null, characters: number) => {
  if (!text) {
    return "";
  }
  return text.length <= characters ? text : `${text.substring(0, characters)}...`;
};

export const getLatitudeLongitudeFromString = (
  value?: string | null
): { longitude: number; latitude: number } | null => {
  if (!value) {
    return null;
  }
  const val = value.split(",");
  if (validateLatitudeLongitude(val)) {
    return { latitude: Number(val[0]), longitude: Number(val[1]) };
  }
  return null;
};

export const validateLatitudeLongitude = (latitudeLongitude?: any[]) => {
  if (!latitudeLongitude) {
    return false;
  }
  if (latitudeLongitude.length !== 2) {
    return false;
  }
  const lat = latitudeLongitude[0];
  const lng = latitudeLongitude[1];

  if (lat === null || lng === null) {
    return false;
  }
  return isFinite(lat) && Math.abs(lat) <= 90 && isFinite(lng) && Math.abs(lng) <= 180;
};
