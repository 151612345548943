import { Button, Icon } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { getAlerts } from "api/icarusApi";
import { alertsRefreshTime } from "config";
import { useAuth } from "context/AuthContext";
import React, { FC } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import styles from "../navbarButton/NavbarButton.module.scss";

type Props = {
  isAuthenticated: boolean;
  to: string;
};

const AlertBadgeNavbarButton: FC<Props> = ({ isAuthenticated, to }) => {
  const [, , { handleError }] = useAuth();
  const { data } = useQuery("alerts", getAlerts, {
    enabled: isAuthenticated,
    refetchInterval: alertsRefreshTime,
    onError: (error: any) => {
      handleError({ error, showErrorToast: false });
    }
  });
  const badgeNumber = data?.totalAlertHits || 0;

  const getTooltipText = () => {
    if (badgeNumber > 99) {
      return "99+ alerts";
    } else if (badgeNumber === 1) {
      return `${badgeNumber} alert`;
    } else if (badgeNumber > 0 && badgeNumber < 99) {
      return `${badgeNumber} alerts`;
    }
    return "Alerts";
  };

  return (
    <Tooltip2 content={getTooltipText()} placement="left" portalClassName={styles.positionTooltip}>
      <Link to={to}>
        <Button
          id={`navbarButton-alert`}
          data-testid={`navbarButton-alert`}
          minimal={true}
          className={styles.alertButton}
        >
          <Icon icon="notifications"></Icon>
          {badgeNumber > 0 && <div data-testid="badgeIcon" className={styles.badgeContainer}></div>}
        </Button>
      </Link>
    </Tooltip2>
  );
};

export default AlertBadgeNavbarButton;
