import React from "react";

type Props = {
  fill?: string;
};

const AdvancedSearchIcon: React.FC<Props> = props => {
  const cls1 = { fill: "none", strokeMiterlimit: 10, strokeWidth: "2px", stroke: "#068dc9" };
  const cls2 = { fill: "none", strokeMiterlimit: 10, strokeWidth: "2", stroke: "#ccc" };
  return (
    <svg width="17.52" height="14.28" viewBox="0 0 17.52 14.28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line style={cls1} x1="12.02" y1="1.87" x2="12.02" y2="12.87" />
      <line style={cls1} x1="6.52" y1="7.37" x2="17.52" y2="7.37" />
      <path style={cls2} d="M1,5.43A4.44,4.44,0,1,1,7.16,9.52a4.53,4.53,0,0,1-1.73.35A4.43,4.43,0,0,1,1,5.43Z" />
      <line style={cls2} x1="11.48" y1="13.65" x2="7.93" y2="9.22" />
    </svg>
  );
};

export default AdvancedSearchIcon;
