import React from "react";

type Props = {
  fill?: string;
};

const UserProfileIcon: React.FC<Props> = props => {
  const { fill = "#068dc9" } = props;
  return (
    <svg width="16" height="16.25" viewBox="0 0 16 16.25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect fill="#ccc" x="5" y="9.25" width="6" height="5" />
          <circle fill="#ccc" cx="8" cy="4" r="4" />
          <path fill={fill} d="M11,9.25l-3,3-3-3s-5,1-5,7H16S16,10.08,11,9.25Z" />
          <circle fill="#ccc" cx="8" cy="13.25" r="0.38" />
          <circle fill="#ccc" cx="8" cy="14.25" r="0.38" />
          <circle fill="#ccc" cx="8" cy="15.25" r="0.38" />
        </g>
      </g>
    </svg>
  );
};

export default UserProfileIcon;
