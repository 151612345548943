import Loading from "../loading/Loading";

const FullPageSpinner = () => {
  return (
    <div className="loading-app">
      <Loading size="large"></Loading>
    </div>
  );
};

export default FullPageSpinner;
