import { ActionProps, Intent, Toaster } from "@blueprintjs/core";

export const AppToaster = Toaster.create();

export const showErrorToaster = (message: string, timeout = 5000, action?: ActionProps) => {
  if (typeof message !== "string") {
    return;
  }

  AppToaster.show({
    message,
    intent: Intent.DANGER,
    timeout,
    action
  });
};

export const showSuccessToaster = (message: string, timeout = 5000) => {
  if (typeof message !== "string") {
    return;
  }

  AppToaster.show({
    message,
    intent: Intent.SUCCESS,
    timeout
  });
};

export const showInfoToaster = (message: string) => {
  if (typeof message !== "string") {
    return;
  }

  AppToaster.show({
    message,
    intent: Intent.PRIMARY
  });
};
