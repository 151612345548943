export const LoadingMessages: string[] = [
  "Pressurizing hydraulics…",
  "Tightening bolts…",
  "Kicking tires...",
  "Loading cargo...",
  "Radioing ground...",
  "Filing flight plan...",
  "Adding fuel...",
  "Cycling power...",
  "Topping off oil...",
  "Prop clear!",
  "Final run-up...",
  "Taxiing...",
  "Pushing off...",
  "Now boarding...",
  "Checking weather..."
];
