import * as Sentry from "@sentry/react";
import jwt_decode from "jwt-decode";
import React, { useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";
import { Action } from "redux-actions";

import * as authActions from "../actions/auth";
import { getUserData, login as loginApi, logout as logoutApi } from "../api/icarusApi";
import { AuthState } from "../reducers/auth";
import authReducer, { INITIAL_STATE } from "../reducers/auth";
import { JwtValue, LoginInput, LoginResult } from "../types/IcarusTypes";
import { showErrorToaster, showSuccessToaster } from "../utils/toaster";
import { callIntercom } from "../utils/useIntercom";

const AuthStateContext = React.createContext<AuthState | undefined>(undefined);
const AuthDispatchActionsContext = React.createContext<authActions.AuthHelpers | undefined>(undefined);
const AuthDispatchContext = React.createContext<React.Dispatch<Action<any>> | undefined>(undefined);

type Props = {
  store?: AuthState;
};

const AuthProvider: React.FC<Props> = ({ children, store }) => {
  const queryClient = useQueryClient();
  const [state, dispatch] = React.useReducer(authReducer, store || INITIAL_STATE);
  const logoutRequest = useCallback(async () => {
    queryClient.removeQueries();
    window.Intercom("shutdown");
    dispatch(authActions.logoutSuccess());
    showSuccessToaster("You have logged out");
    try {
      await logoutApi();
    } catch (error) {
      console.error(error);
    } finally {
      localStorage.removeItem("token");
    }
  }, [queryClient]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    let decoded;
    if (token) {
      try {
        decoded = jwt_decode<JwtValue>(token);
      } catch (e) {
        localStorage.removeItem("token");
        dispatch(authActions.loginFailed(""));
        dispatch(authActions.setIsSettingUpApp(false));
        return;
      }
    } else {
      callIntercom();
      dispatch(authActions.setIsSettingUpApp(false));
      return;
    }
    dispatch(authActions.setIsSettingUpApp(true));
    if (!decoded.user) {
      // any time a user object parse error happens, del the token
      localStorage.removeItem("token");
      dispatch(authActions.loginFailed(""));
      dispatch(authActions.setIsSettingUpApp(false));
      return;
    }
    getUserData(decoded.user.id)
      .then(user => {
        callIntercom(user);
        dispatch(authActions.updateUserSuccess(user));
        dispatch(authActions.loginSuccess());
        dispatch(authActions.setIsSettingUpApp(false));
      })
      .catch((error: any) => {
        // any time a user object parse error happens, del the token
        localStorage.removeItem("token");
        // handleError({ error, showErrorToast: false });
        dispatch(authActions.loginFailed(""));
        dispatch(authActions.setIsSettingUpApp(false));
      });
  }, [dispatch]);

  const handleError = useCallback(
    ({
      error,
      errorMessage,
      showErrorToast = true
    }: {
      error: unknown;
      showErrorToast?: boolean;
      errorMessage?: string;
    }) => {
      console.error(error);

      // check if should send error to sentry. only send if
      // user does not exist or user exists and has blindLogging disabled
      const me = state.me;
      if ((me && me.plan && !me.plan.permissions.blindLogging) || !me || !me.plan) {
        Sentry.captureException(error);
      }

      if (typeof error === "string" && error === "Refresh token required to refresh JWT") {
        logoutRequest();
      }
      if (showErrorToast) {
        const errorString = typeof error === "string" ? error : "";
        showErrorToaster(errorMessage && errorMessage.length > 0 ? errorMessage : errorString);
      }
    },
    [logoutRequest, state]
  );

  async function loginRequest(payload: LoginInput) {
    try {
      dispatch(authActions.loginStart());
      const response: LoginResult = await loginApi(payload);
      const decoded = jwt_decode<JwtValue>(response.token);
      localStorage.setItem("token", response.token);
      dispatch(authActions.setIsSettingUpApp(true));
      const user = await getUserData(decoded.user.id);
      callIntercom(user);
      dispatch(authActions.updateUserSuccess(user));
      dispatch(authActions.setIsSettingUpApp(false));
      dispatch(authActions.loginSuccess());
    } catch (error) {
      const errorMessage = error ? error : "Something went wrong internally. Please try again later.";
      handleError({ error, errorMessage: errorMessage, showErrorToast: true });
      dispatch(authActions.loginFailed(""));
    }
  }

  const dispatchActions = { loginRequest, logoutRequest, handleError };

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        <AuthDispatchActionsContext.Provider value={dispatchActions}>{children}</AuthDispatchActionsContext.Provider>
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("AuthStateContext must be used within a AuthProvider");
  }
  return context;
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("AuthDispatchContext must be used within a AuthProvider");
  }
  return context;
}

function useAuthDispatchActions() {
  const context = React.useContext(AuthDispatchActionsContext);
  if (context === undefined) {
    throw new Error("AuthDispatchActionsContext must be used within a AuthProvider");
  }
  return context;
}

function useAuth() {
  const res: [AuthState, React.Dispatch<any>, authActions.AuthHelpers] = [
    useAuthState(),
    useAuthDispatch(),
    useAuthDispatchActions()
  ];
  return res;
}

export { AuthProvider, useAuth };
