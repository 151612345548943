import { Alignment, Button, Classes, Navbar } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../../../assets/images/icarus-logo-dark.svg";
import { routes } from "../../../routes";
import { showIntercomMessenger } from "../../../utils/useIntercom";
import useMediaQuery from "../../../utils/useMediaQuery";
import MenuDrawer from "../../home/menuDrawer/MenuDrawer";
import AlertBadgeNavbarButton from "../alertBadgeNavbarButton/AlertBadgeNavbarButton";
import MenuAccount, { Props as MenuAccountProps } from "../menuAccount/MenuAccount";
import NavbarButton from "../navbarButton/NavbarButton";
import SearchInput from "../searchInput/SearchInput";
import styles from "./Header.module.scss";

type Props = {
  isAuthenticated: boolean;
  onBasicSearch?: (text: string) => void;
  onMenuDrawerClick?: () => void;
} & MenuAccountProps;

const Header: React.FC<Props> = props => {
  const { isAuthenticated, onLogoutClicked, onBasicSearch } = props;
  const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false);

  const isMobile = useMediaQuery("(max-width: 768px)");
  const renderAuthenticatedButtons = () => {
    if (isMobile) {
      return (
        <Navbar.Group align={Alignment.RIGHT}>
          <SearchInput className={styles.searchInput} onSearch={onBasicSearch}></SearchInput>
        </Navbar.Group>
      );
    }
    return (
      <Navbar.Group align={Alignment.RIGHT}>
        <div className={styles.containerRow}>
          <SearchInput className={styles.searchInput} onSearch={onBasicSearch}></SearchInput>
          <NavbarButton type="home" tootltipText="Home" to={routes.home.path}></NavbarButton>
          <NavbarButton type="radar" tootltipText="Radar Mode" to={routes.radar.path}></NavbarButton>
          <AlertBadgeNavbarButton isAuthenticated={isAuthenticated} to={routes.alerts.path}></AlertBadgeNavbarButton>
          <NavbarButton type="advanced" tootltipText="Advanced Search" to={routes.advancedSearch.path}></NavbarButton>
          <NavbarButton type="directory" tootltipText="Saved Items" to={routes.myItems.path}></NavbarButton>
          <NavbarButton type="help" tootltipText="Help" onClick={() => showIntercomMessenger()}></NavbarButton>
          <Popover2
            minimal
            content={<MenuAccount onLogoutClicked={onLogoutClicked}></MenuAccount>}
            placement="bottom-start"
          >
            <NavbarButton type="account" tootltipText="Account"></NavbarButton>
          </Popover2>
        </div>
      </Navbar.Group>
    );
  };

  const handleLogoutFromMenu = () => {
    setIsMenuDrawerOpen(false);
    if (onLogoutClicked) onLogoutClicked();
  };

  return (
    <div className={Classes.DARK}>
      <Navbar style={{ zIndex: 30 }}>
        <Navbar.Group align={Alignment.LEFT}>
          {isMobile && (
            <Button data-testid="hamburguer" icon="menu" minimal onClick={() => setIsMenuDrawerOpen(true)}></Button>
          )}
          <Navbar.Heading>
            <Link to={routes.home.path} className={styles.title}>
              <Logo className={styles.logo} data-testid="headerLogo" />
            </Link>
          </Navbar.Heading>
        </Navbar.Group>
        {isAuthenticated && renderAuthenticatedButtons()}
      </Navbar>
      <MenuDrawer
        isAuthenticated={isAuthenticated}
        isOpen={isMenuDrawerOpen}
        onClose={() => setIsMenuDrawerOpen(false)}
        onLogoutClicked={handleLogoutFromMenu}
      ></MenuDrawer>
    </div>
  );
};

export default Header;
